

function NavBar() {
    
    return (
      <nav className="navbar navbar-expand-lg">
          <div className="container-fluid">
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                  aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse nav justify-content-end" id="navbarSupportedContent">
                  <div className="btn-group">
                     {/* <img src="" alt="icono de usuario"></img> */}
                      <div style={{cursor: "pointer"}} className="fs-4 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                          usuario
                      </div>
                      <ul className="dropdown-menu" style={{ left: -80 }}>
                          <li>
                              <a className="dropdown-item" href="/">
                                  Cerrar Sesión
                              </a>
                          </li>
                      </ul>
                  </div>
              </div>
          </div>
      </nav>
    );
}

export default NavBar;