import React, { useState, useEffect } from 'react';
import '../public/css/eventform.css'
import prueba from '../assets/images/evento-plantilla.jpg'

export default function EventForm({ event, onSubmit }) {
    const [title, setTitle] = useState("");
    const [mode, setMode] = useState("");
    const [cost, setCost] = useState("");
    const [expiresDate, setExpiresDate] = useState("");
    const [expiresTime, setExpiresTime] = useState("");
    const [organizerEmail, setOrganizerEmail] = useState("");
    const [organizerPhone, setOrganizerPhone] = useState("");
    const [location, setLocation] = useState("");
    const [eventTime, setEventTime] = useState("");

    useEffect(() => {
        if(event) {
            setTitle(event.title);
            setMode(event.mode);
            setCost(event.cost);
            setExpiresDate(event.expiresDate);
            setExpiresTime(event.expiresTime);
            setOrganizerEmail(event.organizerEmail);
            setOrganizerPhone(event.organizerPhone);
            setLocation(event.location);
            setEventTime(event.eventTime);
        }
    }, [event]);


    function isValidEmail(email) {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailRegex.test(email);
    }

    function isValidPhoneNumber(phoneNumber) {
        return /^[0-9]{10}$/.test(phoneNumber);
    }

    const handleSubmit = e => {
        e.preventDefault();
        if (!isValidEmail(organizerEmail)) {
            alert('Correo electrónico no válido');
            return;
        }

        if (!isValidPhoneNumber(organizerPhone)) {
            alert('Número de teléfono no válido');
            return;
        }
        onSubmit({
            title,
            mode,
            cost,
            expiresDate,
            expiresTime,
            organizerEmail,
            organizerPhone,
            location,
            eventTime
        });
    }

    return (
        <form onSubmit={handleSubmit} className='form-container'>
            <div className="form-left">
                <input value={title} onChange={e => setTitle(e.target.value)} placeholder="Título del Evento" type='text' />
                <select id="modalidad" value={mode} onChange={e => setMode(e.target.value)}>
                    <option value="">-- Seleccione una Modalidad --</option>
                    <option value="Gratuita">Gratuita</option>
                    <option value="Pago">Pago</option>
                </select>
                <input value={cost} onChange={e => setCost(e.target.value)} placeholder="Costo" type='number'/>
                <input value={expiresDate} onChange={e => setExpiresDate(e.target.value)} placeholder="Fecha de Vencimiento" type='date'/>
                <input value={expiresTime} onChange={e => setExpiresTime(e.target.value)} placeholder="Hora de Vencimiento" type='time'/>
                <input value={organizerEmail} onChange={e => setOrganizerEmail(e.target.value)} placeholder="Correo del Organizador" type='email'/>
                <input value={organizerPhone} onChange={e => setOrganizerPhone(e.target.value)} placeholder="Telefono del Organizador" type='tel'/>
                <input value={location} onChange={e => setLocation(e.target.value)} placeholder="Lugar del Evento" type='text'/>
                <input value={eventTime} onChange={e => setEventTime(e.target.value)} placeholder="Hora del evento" type='time'/>
            </div>

            <div className="form-right">
                <img src={prueba} alt="Imagen del Evento" className="event-image" />
                <div className="image-buttons">
                    <button type="button" className='custom-reemplazar'>Reemplazar Imagen</button>
                    <button type="button" className='custom-asis'>Asistencia</button>
                </div>
            </div>
           
        </form>
    );
}
