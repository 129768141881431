import './App.css';
import DashboardEventos from './app/pages/dashboard/dashboard';
import NavBar from './app/components/navbar';
import { NavLink, Outlet } from 'react-router-dom';
import './app/public/css/side-bar.css';


function App() {
  return (
    <>
        <NavBar></NavBar>
        <div className='container-fluid'>
            <div className='row'>
                <div className='side-bar col-auto col-md-2 min-vh-100'>
                    <br></br>
                    <br></br>
                    <div className='mb-lg-5 mt-lg-4 text-decoration-none text-black d-flex justify-content-center align-items-center ms-3 mt-2' style={{opacity: 0.5}}>
                        <span className='ms-1 fs-2'><b>HIGHTECH</b></span>
                    </div>
                  
                    <ul className='nav nav-pills flex-column text-center'>
                        <li className='nav-item fs-5'>
                            <NavLink to={'/mi-cuenta'} className={({ isActive }) => isActive ? "nav-link py-3 a-active": ""} >
                               Mi Cuenta 
                            </NavLink>
                        </li>
                        <br></br>
                        <br></br>
                        <li className='nav-item fs-5'>
                            <NavLink to={'/eventos'} className={({ isActive }) => isActive ? "nav-link py-3 a-active": ""}>
                                <span className='fs-4 my-1 ms-2'> Eventos </span>
                            </NavLink>
                        </li>
                    </ul>

                </div>
                
                <div className='content-area col'>
                    <Outlet></Outlet>
                </div>
                
            </div>
        </div>
      </>
  );
}

export default App;
