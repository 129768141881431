import '../../public/css/dashboard.css'
import { Search } from 'react-feather';
import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import EventForm from '../../components/eventform';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import 'styled-components'

export default function DashboardEventos(){
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [dataEvents, setDataEvents] = useState([]);

    const handleEdit = (event) => {
        setSelectedEvent(event);
        setIsModalOpen(true); 
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedEvent(null);
    };

    const handleEventFormSubmit = (eventData) => {
        
        console.log(eventData);
    
       
        setIsModalOpen(false);
    };

    // columnas para datatable de eventos
    const columns = [
        { name: 'Evento', selector:(row) => row.nombre, sortable:true, $center:'true'}, 
        { name: 'Modalidad', selector: (row) => row.modalidad_id, sortable: true, $center:'true'},
        { name: 'Estado', selector: (row) => row.activo, sortable: true, $center:'true', 
            cell: (row) => (
                <div>
                {row.activo ? (
                    <span style={{ color: 'green' }}>Activo</span>
                ) : (
                    <span style={{ color: 'red' }}>Inactivo</span>
                )}
                </div>
            ),
        },
        { name: 'Creado', selector: (row) => row.created_at, sortable: true, $center:'true'},
        { name: 'Vencimiento', selector: (row) => row.fecha_vencimiento, sortable: true, $center:'true'},
        { name: 'Acciones', selector: (row) => row.id,$button: 'true',
            cell: (row) => (
                <button type="button" className="button-editar" >
                    Editar
                </button>
            )}
    ];

    //obtenemos los datos de la API y  seteamos datos
    const fetchDataEvents = async () => {
        try {
          const response = await fetch('http://127.0.0.1:8000/api/eventsAll');
          const data = await response.json();
          console.log("dtos eventos: ", data);
          setDataEvents(data);
        } catch (error) {

          console.error("Ocurrió un error: ",error);

        }
      }

    // cargamos datos al renderizar vista
    useEffect(() => {
        fetchDataEvents();
    }, []);
    
    // cambiamos idioma de la paginación
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };  

    return(
        
            <div>
                <div className="actions-container">
                    <div className='container-Event'>
                        <button color="#6E6E6E" strokeWidth={0.5} className='crearEvent'>
                            Crear evento
                        </button>
                    </div>
               </div>
               <div className='container-table'>
               <DataTableExtensions
                    columns={columns}
                    data={dataEvents}
                    print={false}
                    export={false}
                >
                    <DataTable
                        columns={columns}
                        data={dataEvents}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        responsive
                        striped
                        noHeader
                        defaultSortField="Evento"
                        defaultSortAsc={false}
                        highlightOnHover
                    />
                </DataTableExtensions>

               </div>
               {/* Modal para el formulario de evento */}
               <Modal show={isModalOpen} onHide={closeModal} centered>
                    <Modal.Header>
                        <Modal.Title>Evento</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <EventForm event={selectedEvent} onSubmit={handleEventFormSubmit} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeModal} className="custom-boton">
                         Copiar link
                        </Button>
                        <Button variant="primary" onClick={closeModal} className="custom-boton">
                            Guardar
                        </Button>
                    </Modal.Footer>
                
                </Modal>
            </div>
        
    )
}

/*<Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
                    Copiar Link
                    </Button>
                    <Button variant="primary" onClick={closeModal}>
                    Guardar
                    </Button>
                </Modal.Footer>*/